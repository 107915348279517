import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import ButtonWithIcon from "../Button/ButtonWithIcon";

const About = ({ className }) => {
   const data = useStaticQuery(graphql`
      {
         aboutUsDesktop: file(relativePath: { eq: "repeating/about-us/about-us-desktop.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         aboutUsMobile: file(relativePath: { eq: "repeating/about-us/about-us-mobile.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
      }
   `);

   return (
      <div>
         <section className={`${className}`}>
            <div className="container">
               <div className="hidden md:block mb-12">
                  <Img fadeIn={false} loading="eager" fluid={data.aboutUsDesktop.childImageSharp.fluid} alt="About Us" />
               </div>
               <div className="md:hidden mb-8">
                  <Img fadeIn={false} loading="eager" fluid={data.aboutUsMobile.childImageSharp.fluid} alt="About Us" />
               </div>

               <div className="grid grid-cols-1 md:grid-cols-12">
                  <div className="md:col-start-1 md:col-span-4">
                     <h2>About Our Fiberglass Manufacturing Company</h2>
                  </div>
                  <div className="md:col-end-13 md:col-span-8 md:pl-18">
                     <p>
                        Painted Rhino is a family-owned fiberglass and composite manufacturing company based in Riverside. We take your product from
                        conception to completion and produce it in volume. With nearly 25 years of experience in the composite, vacuum forming, and
                        urethane plastic industry, there is really nothing our team can't build.
                     </p>
                     <ButtonWithIcon href="/about/" text="Learn More" />
                  </div>
               </div>
            </div>
         </section>
      </div>
   );
};

export default About;
