import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SliderHero from "../Slider/SliderHero";

const StyledHero = styled.section`
   ${tw`relative`}
   .split-grid {
      @media (min-width: 1024px) {
         min-height: 720px;
      }
   }
   .text-wrapper {
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "1/4" && backgroundImageSide === "left" ? tw`lg:col-end-13 lg:col-span-3 lg:pl-10 xl:lg:pl-20` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "1/4" && backgroundImageSide === "right" ? tw`lg:col-start-1 lg:col-span-3 lg:pr-10 xl:pr-20` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "1/3" && backgroundImageSide === "left" ? tw`lg:col-end-13 lg:col-span-4 lg:pl-12 xl:lg:pl-24` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "1/3" && backgroundImageSide === "right" ? tw`lg:col-start-1 lg:col-span-4 lg:pr-12 xl:pr-24` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "2/5" && backgroundImageSide === "left" ? tw`lg:col-end-13 lg:col-span-5 lg:pl-12 xl:lg:pl-24` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "2/5" && backgroundImageSide === "right" ? tw`lg:col-start-1 lg:col-span-5 lg:pr-12 xl:pr-24` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "1/2" && backgroundImageSide === "left" ? tw`lg:col-end-13 lg:col-span-6 lg:pl-16 xl:lg:pl-32` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "1/2" && backgroundImageSide === "right" ? tw`lg:col-start-1 lg:col-span-6 lg:pr-16 xl:pr-32` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "3/5" && backgroundImageSide === "left" ? tw`lg:col-end-13 lg:col-span-7 lg:pl-16 xl:lg:pl-32` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "3/5" && backgroundImageSide === "right" ? tw`lg:col-start-1 lg:col-span-7 lg:pr-16 xl:pr-32` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "2/3" && backgroundImageSide === "left" ? tw`lg:col-end-13 lg:col-span-8 lg:pl-16 xl:lg:pl-32` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "2/3" && backgroundImageSide === "right" ? tw`lg:col-start-1 lg:col-span-8 lg:pr-16 xl:pr-32` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "3/4" && backgroundImageSide === "left" ? tw`lg:col-end-13 lg:col-span-9 lg:pl-16 xl:lg:pl-32` : null};
      ${({ textWrapperWidth, backgroundImageSide }) =>
         textWrapperWidth === "3/4" && backgroundImageSide === "right" ? tw`lg:col-start-1 lg:col-span-9 lg:pr-16 xl:pr-32` : null};
      ${({ backgroundImageSide }) => (backgroundImageSide === "left" ? tw`order-2` : tw`order-2 lg:order-1`)};
   }
   .background-image-wrapper {
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "1/4" && backgroundImageSide === "left" ? tw`lg:col-start-1 lg:col-span-3` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "1/4" && backgroundImageSide === "right" ? tw`lg:col-end-13 lg:col-span-3` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "1/3" && backgroundImageSide === "left" ? tw`lg:col-start-1 lg:col-span-4` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "1/3" && backgroundImageSide === "right" ? tw`lg:col-end-13 lg:col-span-4` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "2/5" && backgroundImageSide === "left" ? tw`lg:col-start-1 lg:col-span-5` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "2/5" && backgroundImageSide === "right" ? tw`lg:col-end-13 lg:col-span-5` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "1/2" && backgroundImageSide === "left" ? tw`lg:col-start-1 lg:col-span-6` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "1/2" && backgroundImageSide === "right" ? tw`lg:col-end-13 lg:col-span-6` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "3/5" && backgroundImageSide === "left" ? tw`lg:col-start-1 lg:col-span-7` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "3/5" && backgroundImageSide === "right" ? tw`lg:col-end-13 lg:col-span-7` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "2/3" && backgroundImageSide === "left" ? tw`lg:col-start-1 lg:col-span-8` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "2/3" && backgroundImageSide === "right" ? tw`lg:col-end-13 lg:col-span-8` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "3/4" && backgroundImageSide === "left" ? tw`lg:col-start-1 lg:col-span-9` : null};
      ${({ backgroundImageWrapperWidth, backgroundImageSide }) =>
         backgroundImageWrapperWidth === "3/4" && backgroundImageSide === "right" ? tw`lg:col-end-13 lg:col-span-9` : null};
      ${({ backgroundImageSide }) => (backgroundImageSide === "left" ? tw`order-1` : tw`order-1 lg:order-2`)};
   }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
   ${tw`h-100vw md:h-75vw lg:h-auto transform`}
   ${({ hasContainer }) => (hasContainer ? tw`lg:w-full` : tw`lg:w-50vw`)};
   ${({ backgroundImageSide }) => (backgroundImageSide === "left" ? tw`lg:-translate-x-full lg:left-full` : tw`lg:translate-x-0`)};
   min-height: ${({ minHeightMobile }) => {
      return minHeightMobile;
   }};
   &:before {
      background-position: ${({ backgroundPositionMobile }) => {
         return backgroundPositionMobile;
      }} !important;
      background-size: ${({ backgroundSizeMobile }) => {
         return backgroundSizeMobile;
      }} !important;
   }
   @media (min-width: 1024px) {
      min-height: ${({ minHeightDesktop }) => {
         return minHeightDesktop;
      }};
      &:before {
         background-position: ${({ backgroundPositionDesktop }) => {
            return backgroundPositionDesktop;
         }} !important;
         background-size: ${({ backgroundSizeDesktop }) => {
            return backgroundSizeDesktop;
         }} !important;
      }
   }
`;

const Hero = ({
   children,
   className,
   hasContainer,
   backgroundImageWrapperWidth,
   textWrapperWidth,
   backgroundImage1,
   backgroundImage2,
   backgroundImage3,
   backgroundImageSide,
   minHeightDesktop,
   minHeightMobile,
   backgroundPositionDesktop,
   backgroundPositionMobile,
   backgroundSizeDesktop,
   backgroundSizeMobile,
}) => {
   return (
      <StyledHero
         textWrapperWidth={textWrapperWidth}
         backgroundImageWrapperWidth={backgroundImageWrapperWidth}
         backgroundImageSide={backgroundImageSide}
      >
         <div className="container px-0 lg:px-6">
            <div className="split-grid grid grid-cols-1 items-center gap-y-8 lg:grid-cols-12">
               <div className="text-wrapper px-6 lg:row-start-1 lg:px-0">{children}</div>
               <div className="background-image-wrapper lg:row-start-1">
                  <SliderHero>
                     {backgroundImage1 ? (
                        <StyledBackgroundImage
                           className={className}
                           hasContainer={hasContainer}
                           fixed={backgroundImage1}
                           backgroundImageSide={backgroundImageSide}
                           minHeightDesktop={minHeightDesktop}
                           minHeightMobile={minHeightMobile}
                           backgroundSizeDesktop={backgroundSizeDesktop}
                           backgroundSizeMobile={backgroundSizeMobile}
                           backgroundPositionDesktop={backgroundPositionDesktop}
                           backgroundPositionMobile={backgroundPositionMobile}
                        />
                     ) : null}

                     {backgroundImage2 ? (
                        <StyledBackgroundImage
                           className={className}
                           hasContainer={hasContainer}
                           fixed={backgroundImage2}
                           backgroundImageSide={backgroundImageSide}
                           minHeightDesktop={minHeightDesktop}
                           minHeightMobile={minHeightMobile}
                           backgroundSizeDesktop={backgroundSizeDesktop}
                           backgroundSizeMobile={backgroundSizeMobile}
                           backgroundPositionDesktop={backgroundPositionDesktop}
                           backgroundPositionMobile={backgroundPositionMobile}
                        />
                     ) : null}

                     {backgroundImage3 ? (
                        <StyledBackgroundImage
                           className={className}
                           hasContainer={hasContainer}
                           fixed={backgroundImage3}
                           backgroundImageSide={backgroundImageSide}
                           minHeightDesktop={minHeightDesktop}
                           minHeightMobile={minHeightMobile}
                           backgroundSizeDesktop={backgroundSizeDesktop}
                           backgroundSizeMobile={backgroundSizeMobile}
                           backgroundPositionDesktop={backgroundPositionDesktop}
                           backgroundPositionMobile={backgroundPositionMobile}
                        />
                     ) : null}
                  </SliderHero>
               </div>
            </div>
         </div>
      </StyledHero>
   );
};

export default Hero;
