import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import HeroSplitSlider from "../components/Hero/HeroSplitSlider";
import IndustriesWeServe from "../components/Repeating/IndustriesWeServe";
import Services from "../components/Repeating/Services";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import Bio from "../components/Repeating/Bio";

const Page = ({ data }) => {
   const heroImages1 = [
      data.heroDesktop1.childImageSharp.fixed,
      {
         ...data.heroMobile1.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   const heroImages2 = [
      data.heroDesktop2.childImageSharp.fixed,
      {
         ...data.heroMobile2.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   const heroImages3 = [
      data.heroDesktop3.childImageSharp.fixed,
      {
         ...data.heroMobile3.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   const companiesWeServeImages = [
      data.companiesWeBuiltDesktop.childImageSharp.fixed,
      {
         ...data.companiesWeBuiltMobile.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout headerStyle="overlap" headerLinkColor="white" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Fiberglass Fabrication & Composite Parts Manufacturing Company"
            description="Painted Rhino is a leading California fiberglass fabrication & composite parts manufacturing company serving nationwide. Get a free quote today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroSplitSlider
            hasContainer={false}
            backgroundImageWrapperWidth="1/2"
            textWrapperWidth="1/2"
            backgroundImage1={heroImages1}
            backgroundImage2={heroImages2}
            backgroundImage3={heroImages3}
            backgroundImageSide="right"
            backgroundSizeDesktop="cover"
            backgroundSizeMobile="cover"
            backgroundPositionDesktop="50% 50%"
            backgroundPositionMobile="50% 50%"
            minHeightDesktop="700px"
            minHeightMobile="330px"
         >
            <p className="text-black font-black leading-tighter text-mobile7xl md:text-7xl mb-5">Dream. Build. Repeat.</p>
            <p className="text-black text-opacity-80 text-mobile3xl md:text-mobile4xl">Bringing your vision to life from prototype to product.</p>
            <ButtonSolid as="button" data="modal-contact" text="Get a Quote" />
         </HeroSplitSlider>

         <section className="pt-24 md:pt-32 pb-20 md:pb-28">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-12 lg:gap-x-20 gap-y-8">
                  <div className="md:col-start-1 md:col-span-7">
                     <Img fadeIn={false} loading="eager" fluid={data.intro.childImageSharp.fluid} alt="Since 1997" />
                  </div>
                  <div className="md:col-end-13 md:col-span-5 md:mt-6">
                     <h1>Fiberglass Fabrication & Composite Parts Manufacturing Company</h1>
                     <p className="mb-0">
                        Need quality production or custom-built work? Painted Rhino is a leading custom <a href="https://www.paintedrhino.com/" className="text-[#006ee8]">fiberglass fabrication and composite</a> parts
                        manufacturing company based in California and serving nationwide. We offer a variety of solutions across many industries with
                        speed, quality, and total commitment to your needs. With our decades of experience, our family-owned company can build
                        whatever you require. See what we can do, and let’s build your dreams into reality.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <BackgroundImage fixed={companiesWeServeImages} className="w-full pt-20 md:pt-32 pb-24 md:pb-36">
            <div className="container relative">
               <header className="mb-18 md:mb-22 text-center">
                  <p className="text-black text-sm font-bold tracking-wider uppercase mb-0">Companies We’ve Built For</p>
               </header>

               <div className="grid grid-cols-3 lg:grid-cols-5 gap-x-8 md:gap-x-12 gap-y-12 md:gap-y-20 max-w-5xl mx-auto items-center">
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.clubCar.childImageSharp.fluid} alt="Club Car logo" />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.americanMuseumOfNaturalHistory.childImageSharp.fluid}
                        alt="American Museum Of Natural History logo"
                     />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.disneyland.childImageSharp.fluid} alt="Disneyland logo" />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.ingersollRand.childImageSharp.fluid}
                        alt="Ingersoll Rand logo"
                     />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.kennedySpaceCenter.childImageSharp.fluid}
                        alt="Kennedy Space Center logo"
                     />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.LAX.childImageSharp.fluid} alt="LAX logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.pilot.childImageSharp.fluid} alt="Pilot logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.edison.childImageSharp.fluid} alt="Edison logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.yamaha.childImageSharp.fluid} alt="Yamaha logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.universal.childImageSharp.fluid} alt="Universal logo" />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.theCosmopolitan.childImageSharp.fluid}
                        alt="The Cosmopolitan logo"
                     />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.cinemaSecrets.childImageSharp.fluid}
                        alt="Cinema Secrets logo"
                     />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.GAS.childImageSharp.fluid} alt="GAS logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.lampsPlus.childImageSharp.fluid} alt="Lamps Plus logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.UCR.childImageSharp.fluid} alt="UCR logo" />
                  </div>
               </div>
            </div>
         </BackgroundImage>

         <Services
            className="pt-22 md:pt-36 mb-22 md:mb-32"
            headerHeading="Fiberglass Parts Manufacturing & Composite Services"
            headerText="Whether you’re ready to bring your concept to life or need help with design, our fiberglass fabrication and composite company has solutions."
         />

         <IndustriesWeServe className="pb-20 md:pb-32" />
         <Bio className="pt-20 md:pt-32 mb-20 md:mb-28"/>

         <TestimonialBackgroundImage />

         <About className="pt-20 md:pt-32 mb-20 md:mb-28" />
         <WhyUs className="pb-20 md:pb-28" />

         <CTABackgroundImage />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Homepage_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Homepage_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop1: file(relativePath: { eq: "home/hero-slider-1.jpg" }) {
         childImageSharp {
            fixed(width: 1600, height: 1406) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      heroMobile1: file(relativePath: { eq: "home/hero-slider-1.jpg" }) {
         childImageSharp {
            fixed(width: 414, height: 600) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      heroDesktop2: file(relativePath: { eq: "home/hero-slider-2.jpg" }) {
         childImageSharp {
            fixed(width: 1600, height: 1406) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      heroMobile2: file(relativePath: { eq: "home/hero-slider-2.jpg" }) {
         childImageSharp {
            fixed(width: 414, height: 600) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      heroDesktop3: file(relativePath: { eq: "home/hero-slider-3.jpg" }) {
         childImageSharp {
            fixed(width: 1600, height: 1406) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      heroMobile3: file(relativePath: { eq: "home/hero-slider-3.jpg" }) {
         childImageSharp {
            fixed(width: 414, height: 600) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      intro: file(relativePath: { eq: "home/intro-since-1997.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1280) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      companiesWeBuiltDesktop: file(relativePath: { eq: "home/Companies-We-Built-For-BG-Desktop.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      companiesWeBuiltMobile: file(relativePath: { eq: "home/Companies-We-Built-For-BG-Mobile.jpg" }) {
         childImageSharp {
            fixed(width: 750, height: 1270) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      clubCar: file(relativePath: { eq: "home/Club-Car.png" }) {
         childImageSharp {
            fluid(maxWidth: 117) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      americanMuseumOfNaturalHistory: file(relativePath: { eq: "home/American-Museum-of-Natural-History.png" }) {
         childImageSharp {
            fluid(maxWidth: 154) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      disneyland: file(relativePath: { eq: "home/Disneyland.png" }) {
         childImageSharp {
            fluid(maxWidth: 139) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      ingersollRand: file(relativePath: { eq: "home/Ingersoll-Rand.png" }) {
         childImageSharp {
            fluid(maxWidth: 152) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      kennedySpaceCenter: file(relativePath: { eq: "home/Kennedy-Space-Center.png" }) {
         childImageSharp {
            fluid(maxWidth: 130) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      LAX: file(relativePath: { eq: "home/LAX.png" }) {
         childImageSharp {
            fluid(maxWidth: 83) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      pilot: file(relativePath: { eq: "home/Pilot.png" }) {
         childImageSharp {
            fluid(maxWidth: 149) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      edison: file(relativePath: { eq: "home/Edison.png" }) {
         childImageSharp {
            fluid(maxWidth: 139) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      yamaha: file(relativePath: { eq: "home/Yamaha.png" }) {
         childImageSharp {
            fluid(maxWidth: 160) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      universal: file(relativePath: { eq: "home/Universal.png" }) {
         childImageSharp {
            fluid(maxWidth: 108) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      theCosmopolitan: file(relativePath: { eq: "home/The-Cosmopolitan.png" }) {
         childImageSharp {
            fluid(maxWidth: 136) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      cinemaSecrets: file(relativePath: { eq: "home/cinema-secrets.png" }) {
         childImageSharp {
            fluid(maxWidth: 101) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      GAS: file(relativePath: { eq: "home/GAS-Galpin-Auto-Spots.png" }) {
         childImageSharp {
            fluid(maxWidth: 76) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      lampsPlus: file(relativePath: { eq: "home/Lamps-Plus.png" }) {
         childImageSharp {
            fluid(maxWidth: 146) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      UCR: file(relativePath: { eq: "home/UC-Riverside.png" }) {
         childImageSharp {
            fluid(maxWidth: 128) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;

export default Page;
